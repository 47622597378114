import React from "react";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center bg-light"
      style={{ minHeight: "100vh" }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-12 col-xl-10 text-center">
            <div className="logo-box mb-4 mx-auto">
              <img
                className="w-100 object-fit-cover"
                alt="Logo"
                src="/assets/logo.png"
              />
            </div>
            <p className="lead my-4 text-muted">
              Neem contact met ons op
              <br />
              als u zich zorgen maakt of als u details nodig heeft
              <br />
              via onze e-mail
            </p>
            <a href="mailto:sahar-ahmadi@live.nl" className="btn btn-primary">
              sahar-ahmadi@live.nl
            </a>
            <br />
            <div className="mt-3">
              <Link to="/">Back</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
